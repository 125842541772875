
import { defineComponent, ref } from 'vue';

import { Modal } from 'ant-design-vue';
import type { UploadProps } from 'ant-design-vue';

import MediaCards from '@/components/cards/MediaCards.vue';
import TableList from '@/components/tables/TableList.vue';
import axios from '@/utils/axios';
import { getVideoUrl } from '@/utils/mediaUrl';
const columns = [
  {
    title: 'File name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 200,
  },
  {
    title: 'Video',
    dataIndex: 'mediaUrl',
    key: 'mediaUrl',
  },
  {
    title: 'Scenario',
    dataIndex: 'scenario',
    key: 'scenario',
  },
];

export default defineComponent({
  name: 'UploadImage',
  components: {
    TableList,
    MediaCards,
  },
  props: {
    apiUrl: {
      type: String,
      required: true,
    },
    scenarioUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const imageFileList = ref<UploadProps['fileList']>([]);
    const mediaCardRef = ref();
    const tableListRef = ref();

    const onClick = async () => {
      const { oldFaces, newFaces, scenario, video } =
        await mediaCardRef.value.submit();

      const { status } = await axios.post(props.apiUrl, {
        videoId: video[0].uid,
        imageFaceIds: newFaces.map((item: any) => item.id),
        videoFaceIds: oldFaces.map((item: any) => item.id),
        scenarioId: scenario.uid,
      });
      if (200 <= status && status < 300) {
        Modal.info({
          title: '요청 성공',
          content: '요청 성공했어요. 조금만 기다려보세요',
        });
        tableListRef.value.getApiData();
      } else {
        Modal.error({
          title: '요청 실패',
          content: '다시 한번 요청해보세요',
        });
      }
    };

    return {
      mediaCardRef,
      tableListRef,
      imageFileList,
      columns,
      // func
      getVideoUrl,
      onClick,
    };
  },
});
