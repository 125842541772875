import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_MediaCards = _resolveComponent("MediaCards")!
  const _component_table_list = _resolveComponent("table-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MediaCards, {
      ref: "mediaCardRef",
      type: [
      { name: 'video', props: { search: true } },
      { name: 'ImageMappingCard', props: { search: false } },
      {
        name: 'scenario',
        props: {
          scenarioUrl: _ctx.scenarioUrl,
        },
        event: {
          click: _ctx.onClick,
        },
      },
    ]
    }, {
      guide: _withCtx(() => [
        _createVNode(_component_a_typography_title, { level: 5 })
      ]),
      _: 1
    }, 8, ["type"]),
    _createVNode(_component_table_list, {
      ref: "tableListRef",
      columns: _ctx.columns,
      apis: { list: _ctx.apiUrl },
      type: "video",
      "table-handler-props": { mode: ['download'] }
    }, null, 8, ["columns", "apis"])
  ], 64))
}